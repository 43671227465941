<template>
  <div class="chat-container">
    <div class="header-chat">
      <div class="title">{{ $t('Support.219') }}: {{ ID }} / {{ $t('Support.220') }}: {{ Subject }}</div>
      <button class="back btn btn-danger" @click="hide()">
        <i class="fa fa-times-circle" aria-hidden="true"></i>
      </button>
    </div>
    <div class="content-chat">
      <template v-for="messages in Message">
        <div class="left-chat" :key="messages.ticket_Time" v-if="Info.id !== messages.ticket_User">
          <span>{{ $t('MainContent.122') }}:{{ messages.ticket_Time }}</span>
          <span>{{ messages.ticket_Content }}</span>
        </div>
        <div class="right-chat" :key="messages.ticket_Time" v-else>
          <span>{{ $t('MainContent.122') }}:{{ messages.ticket_Time }}</span>
          <span>{{ messages.ticket_Content }}</span>
        </div>
      </template>
    </div>
    <div class="send-chat">
      <input type="text" v-model.trim="message" class="send" />
      <div class="button-send-group">
        <button class="send-button btn btn-igtrade" @click.prevent="onSendMessage()">
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    ID: Number,
    Subject: String,
    SubjectID: Number
  },
  data() {
    return {
      message: ""
    };
  },
  computed: {
    ...mapGetters({
      Message: "ticket/DetailTicket",
      Info: "dashboard/Info"
    })
  },
  methods: {
    hide() {
      this.$store.commit("HiddenChat");
    },
    getTicketDetail() {
      this.$store.dispatch("ticket/req_getTicketDetail", this.ID);
    },
    onSendMessage() {
      if (!this.ID || !this.Subject) {
        this.$toastr.e(
           this.$t('Message.257'),
           this.$t('Message.258')
        );
        return;
      }
      if (!this.message) {
        this.$toastr.e( this.$t('Message.259'),  this.$t('Message.260'));
        return;
      }

      this.$store.dispatch("ticket/req_postTicketMessage", {
        content: this.message,
        subject: this.SubjectID,
        replyID: this.ID
      });
      this.message = '';
    }
  },
  mounted() {
    if (this.ID) {
      this.getTicketDetail();
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "ticket/SEND_MESSANGE_SUCCESS":
          this.getTicketDetail();
          break;
      }
    });
    // this.getTicketDetail();
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: #fff;
}
.chat-container {
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .header-chat {
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #ffffff2e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    button.back {
      padding: 0;
      background: transparent;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      font-size: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: #fff;
      color: #fff;
    }
  }
  .content-chat::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
  }

  .content-chat::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .content-chat::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  .content-chat {
    height: calc(100% - 120px);
    padding: 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .left-chat {
      background: #c5c5c5;
      min-height: auto;
      max-width: 300px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      padding: 5px;
      margin-bottom: 5px;

      span {
        color: black;
        word-break: break-word;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .right-chat {
      background: #224a6e;
      min-height: auto;
      max-width: 300px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      padding: 5px;
      margin-right: 0;
      margin-left: auto;
      margin-top: 5px;
      margin-bottom: 5px;

      span {
        color: #fff8f8;
        word-break: break-word;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .send-chat {
    height: 70px;
    border-radius: 10px;
    background: #ffffff2e;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding:0 10px;
    input.send {
      flex: 0 0 calc(90% - 15px);
      height: 40px;
      border-radius: 5px;
      color: #fff;
      background: #ffffff59;
      border: 0.5px solid #ffffff87 !important;
      outline: none;
      @media (max-width: 1599px){
        flex: 0 0 80%;
      }
      @media (max-width: 375px){
        flex: 0 0 75%;
      }
    }
    .button-send-group {
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
       @media (max-width: 1599px){
        flex: 0 0 12%;
      }
      @media (max-width: 1299px){
        flex: 0 0 12%;
      }
      @media (max-width: 1099px){
        flex: 0 0 14%;
      }
      @media (max-width: 400px){
        flex: 0 0 15%;
      }
      @media (max-width: 375px){
        flex: 0 0 18%;
      }
      .send-button {
        border-radius: 50%;
        padding: 0;
        width: 40px;
        height: 40px;
        margin: 0;
        outline: none;
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }
}

.chat-container {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid;
  border-radius: 10px;
}
.inputGroup {
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  overflow: hidden;

  input {
    border: none;
    background-color: transparent;
    color: white;
    width: calc(100% - 80px);
  }

  button {
    width: 80px;
    border-radius: 5px;
  }

  input:active,
  input:focus {
    border: none;
    outline: none;
  }
}
.chat-container {
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 2px;
//   border: 1px solid #fff200;
  width: 100%;
  position: relative;
  margin: auto;
  border-radius: 10px !important;
}
</style>
